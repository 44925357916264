<template>
	<div>
		<section class="section">
			<div class="container">
				<h1 class="title jumbo">Safety</h1>

				<h2>Commitment</h2>
				<p>
					Health and Safety is an integral part of our daily work activities. TriQuest NDT is committed to providing a safe and healthy work environment
					that protects our employees, sub-contractors, clients, the public and the environment from harmful incidents. We strive to ensure that our
					operations promote safe work practices and procedures that will continually improve our safety performance.
				</p>

				<p>
					Creating a safe work environment is a number one priority at TriQuest NDT. Through the cooperation and commitment of all employees as well as clients,
					we strive to achieve an accident free workplace. Safety will never be compromised.
				</p>

				<h2>Safety</h2>
				<p>
					All employees are required to participate in the development and implementation of safe work practices through continuous education, training and
					involvement in safety meetings. We are dedicated to identifying, correcting and preventing health, safety, environmental and property hazards, by
					implementing a Safety Program that complies with legislative, regulatory, client and industry standards.
				</p>

				<p>
					TriQuest NDT Management is responsible and accountable for ensuring that our Safety Program is compliant, implemented, and supported by all personnel.
					The success of our Safety Program will also be achieved by employing highly skilled, experienced and trained personnel, and by providing and maintaining
					the proper equipment to ensure every job is completed safely and efficiently.
				</p>

				<h2>COR Certification</h2>
				<p>
					TriQuest NDT maintains the Certificate of Recognition (COR) through the Alberta Construction Safety Association as the Certifying Partner. COR is awarded
					to employers who develop health and safety programs that meet established standards. Achieving and maintaining a valid COR is required for earning a
					financial incentive through the WCB Partnerships in Injury Reduction program.
				</p>
				<div class="certification-badges">
					<span>
						<img :src="require('@/assets/img/badges/acsa.png')" alt="Alberta Construction Safety Association" />
					</span>
					<span>
						<img :src="require('@/assets/img/badges/cor.png')" alt="Workplace Safety Certificate of Recognition" />
					</span>
					<span>
						<img :src="require('@/assets/img/badges/isn.png')" alt="ISNetworld" />
					</span>
					<span>
						<img :src="require('@/assets/img/badges/cqn.png')" alt="CanQual Network" />
					</span>
					<span>
						<img :src="require('@/assets/img/badges/avetta.png')" alt="Avetta" />
					</span>
					<span>
						<img :src="require('@/assets/img/badges/complyworks.png')" alt="ComplyWorks" />
					</span>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	export default {
		name: 'Safety',
		data(){
			return {

			}
		}
	}
</script>

<style></style>
